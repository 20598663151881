// Generated by Framer (41c59c7)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kSOzUiPr1"];

const serializationHash = "framer-xCNwS"

const variantClassNames = {kSOzUiPr1: "framer-v-vffnay"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kSOzUiPr1", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-vffnay", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"kSOzUiPr1"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><SVG className={"framer-2tlnt8"} data-framer-name={"Ellipse 7"} fill={"rgba(0,0,0,1)"} intrinsicHeight={88} intrinsicWidth={88} layoutDependency={layoutDependency} layoutId={"fH3an85Cl"} svg={"<svg width=\"88\" height=\"88\" viewBox=\"0 0 88 88\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M43.9311 0.75544C67.85 0.75544 87.2401 20.1455 87.2401 44.0644C87.2401 67.9832 67.85 87.3733 43.9311 87.3733C20.0122 87.3733 0.62207 67.9832 0.62207 44.0644C0.62207 20.1455 20.0122 0.75544 43.9311 0.75544ZM43.9311 61.3879C53.4987 61.3879 61.2547 53.6319 61.2547 44.0644C61.2547 34.4968 53.4987 26.7408 43.9311 26.7408C34.3635 26.7408 26.6075 34.4968 26.6075 44.0644C26.6075 53.6319 34.3635 61.3879 43.9311 61.3879Z\" fill=\"#F4EEEE\"/>\n</svg>\n"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xCNwS.framer-1mnlp7j, .framer-xCNwS .framer-1mnlp7j { display: block; }", ".framer-xCNwS.framer-vffnay { height: 88px; overflow: hidden; position: relative; width: 88px; }", ".framer-xCNwS .framer-2tlnt8 { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 88
 * @framerIntrinsicWidth 88
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerTW8rGUnay: React.ComponentType<Props> = withCSS(Component, css, "framer-xCNwS") as typeof Component;
export default FramerTW8rGUnay;

FramerTW8rGUnay.displayName = "Circle-hole";

FramerTW8rGUnay.defaultProps = {height: 88, width: 88};

addFonts(FramerTW8rGUnay, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})